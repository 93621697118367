// React imports
import React, { useState, useEffect } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

import rolloverHouse from 'images/svg/rollover_remodelar.svg';
import rolloverClothe from 'images/svg/rollover_ropa.svg';
import rolloverCar from 'images/svg/rollover_carro.svg';
import rolloverHealth from 'images/svg/rollover_health.svg';
import rolloverRandom from 'images/svg/rollover_random.svg';
import rolloverVacation from 'images/svg/rollover_vacaciones.svg';
import rolloverEducation from 'images/svg/rollover_educacion.svg';
import rolloverEmergency from 'images/svg/rollover_emergencia.svg';
import carPositive from 'images/svg/car_savings_positive.svg';
import carNegative from 'images/svg/car_savings_negative.svg';

import clothesPositive from 'images/svg/clothes_savings_positive.svg';
import clothesNegative from 'images/svg/clothes_savings_negative.svg';

import educationPositive from 'images/svg/education_savings_positive.svg';
import educationNegative from 'images/svg/education_savings_negative.svg';

import emergencyPositive from 'images/svg/emergency_savings_positive.svg';
import emergencyNegative from 'images/svg/emergency_savings_negative.svg';

import healthPositive from 'images/svg/health_savings_positive.svg';
import healthNegative from 'images/svg/health_savings_negative.svg';

import housePositive from 'images/svg/house_savings_positive.svg';
import houseNegative from 'images/svg/house_savings_negative.svg';

import randomPositive from 'images/svg/random_savings_positive.svg';
import randomNegative from 'images/svg/random_savings_negative.svg';

import vacationPositive from 'images/svg/vacation_savings_positive.svg';
import vacationNegative from 'images/svg/vacation_savings_negative.svg';

import { styles } from './SaveMoney.style';

function SaveMoney(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showSafariMargin, setMargin] = useState(false);
    const [clicked, setClicked] = useState('clothes');
    const rollovers = {
        house: rolloverHouse,
        car: rolloverCar,
        vacation: rolloverVacation,
        health: rolloverHealth,
        emergency: rolloverEmergency,
        education: rolloverEducation,
        clothes: rolloverClothe,
        random: rolloverRandom,
    };

    return (
        <Box className={clsx(classes.root, showSafariMargin && 'safari-margin')}>
            <Box id="rollover">
                <img src={rollovers[clicked]} alt="Tarjeta y app Cuenca" />
            </Box>
            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <div id="title">
                        <p id="top">¿PARA QUÉ</p>
                    </div>
                    <p id="botTitle">quieres administrar tu dinero?</p>
                </Box>
                <p id="text">
                    ¿Te urge cambiar de teléfono? ¡Define metas! Ahora puedes destinar un lugar en Apartados para cada
                    meta de tus fondos. A cada apartado le puedes asignar un propósito, una objetivo de dinero y un
                    límite de tiempo para alcanzarla.
                </p>
                <Box className={classes.boxes}>
                    <Box className={classes.dynamicBoxes}>
                        <div
                            className={clsx(classes.one, clicked == 'clothes' && 'clicked')}
                            onClick={() => setClicked('clothes')}
                        >
                            <img src={clicked == 'clothes' ? clothesNegative : clothesPositive} />
                        </div>
                        <div
                            className={clsx(classes.two, clicked == 'house' && 'clicked')}
                            onClick={() => setClicked('house')}
                        >
                            <img src={clicked == 'house' ? houseNegative : housePositive} />
                        </div>
                        <div
                            className={clsx(classes.three, clicked == 'emergency' && 'clicked')}
                            onClick={() => setClicked('emergency')}
                        >
                            <img src={clicked == 'emergency' ? emergencyNegative : emergencyPositive} />
                        </div>
                        <div
                            className={clsx(classes.four, clicked == 'education' && 'clicked')}
                            onClick={() => setClicked('education')}
                        >
                            <img src={clicked == 'education' ? educationNegative : educationPositive} />
                        </div>
                    </Box>
                    <Box className={classes.dynamicBoxes}>
                        <div
                            className={clsx(classes.one, clicked == 'vacation' && 'clicked')}
                            onClick={() => setClicked('vacation')}
                        >
                            <img src={clicked == 'vacation' ? vacationNegative : vacationPositive} />
                        </div>
                        <div
                            className={clsx(classes.two, clicked == 'car' && 'clicked')}
                            onClick={() => setClicked('car')}
                        >
                            <img src={clicked == 'car' ? carNegative : carPositive} />
                        </div>
                        <div
                            className={clsx(classes.three, clicked == 'health' && 'clicked')}
                            onClick={() => setClicked('health')}
                        >
                            <img src={clicked == 'health' ? healthNegative : healthPositive} />
                        </div>
                        <div
                            className={clsx(classes.four, clicked == 'random' && 'clicked')}
                            onClick={() => setClicked('random')}
                        >
                            <img src={clicked == 'random' ? randomNegative : randomPositive} />
                        </div>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(SaveMoney);
