// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Carousel from 'components/Carousel';
import GifCarousel from 'components/GifCarousel';

// local imports
import fourthImage from 'images/svg/fourth-step-apartados.svg';
import fifthImage from 'images/svg/fifth-step-apartados.svg';
import sixthImage from 'images/svg/sixth-step-apartados.svg';
import { styles } from './SecondAppRow.style';

function SecondAppRow(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const responsive = useMediaQuery('(min-width:769px)');
    let imagesData = [
        style => <GifCarousel type="fourth_saving_image" style={style} />,
        style => <GifCarousel type="fifth_saving_image" style={style} />,
        style => <GifCarousel type="sixth_saving_image" style={style} />,
    ];

    return responsive ? (
        <Box className={classes.imgContainer}>
            <img src={fourthImage} alt="Pasos para crear apartados" />
            <img src={fifthImage} alt="Pasos para crear apartados" />
            <img src={sixthImage} alt="Pasos para crear apartados" />
        </Box>
    ) : (
        <Box className={classes.root}>
            <Box className={classes.carousel}>
                <Carousel dataPerPage={imagesData.length} carouselSlides={imagesData} />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(SecondAppRow);
