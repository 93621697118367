import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            textAlign: 'center',
            maxWidth: '300px',
            marginLeft: '18%',
            [theme.breakpoints.down(701)]: {
                alignItems: 'flex-start',
                marginLeft: '0',
            },
            [theme.breakpoints.down(500)]: {
                maxWidth: '250px',
            },
            [theme.breakpoints.down(400)]: {
                maxWidth: '200px',
            },
        },
        image: {
            marginBottom: '34px',
            '& img': { marginBottom: 0, width: 'auto', height: '530px', left: '20%' },
            zIndex: -999,
            [theme.breakpoints.down(850)]: {
                '& img': { height: '500px' },
            },
            [theme.breakpoints.down(850)]: {
                '& img': { height: '500px' },
                marginBottom: '27px',
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '34px',
                '& img': { height: '530px' },
            },
            [theme.breakpoints.down(550)]: {
                marginBottom: '30px',
                '& img': { height: '450px' },
            },
            [theme.breakpoints.down(450)]: {
                '& img': { height: '400px' },
            },
            [theme.breakpoints.down(400)]: {
                '& img': { height: '350px' },
            },
            [theme.breakpoints.down(350)]: {
                marginBottom: '20px',
                '& img': { height: '300px' },
            },
        },
        description: {
            fontFamily: 'MuseoSans, sans-serif',
            fontWeight: 700,
            fontSize: '14.5px',
            color: theme.palette.primary.gray,
            textAlign: 'center',
            letterSpacing: '0.88px',
            lineHeight: '21px',
            marginBottom: '25px',
            [theme.breakpoints.down(1001)]: {
                fontSize: '12.5px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(701)]: {
                marginLeft: '15px',
            },
            [theme.breakpoints.down(650)]: {
                marginLeft: '15px',
            },
            [theme.breakpoints.down(550)]: {
                fontSize: '11.5px',
                lineHeight: '17px',
                marginLeft: '5px',
            },
            [theme.breakpoints.down(450)]: {
                fontSize: '10.5px',
                lineHeight: '15px',
                marginLeft: '0px',
            },
            [theme.breakpoints.down(400)]: {
                fontSize: '9px',
                marginRight: '8px',
            },
            [theme.breakpoints.down(350)]: {
                fontSize: '7px',
                marginRight: '0px',
                lineHeight: '13px',
            },
        },
    });
