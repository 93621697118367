import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            width: '1329px',
            marginBottom: '325px',
            marginTop: '325px',
            display: 'flex',
            flexDirection: 'row',
            '& #rollover': {
                alignSelf: 'end',
                height: '100%',
                width: '704px',
                '& img': {
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                },
            },
            [theme.breakpoints.down(1400)]: { width: '100%' },
            [theme.breakpoints.down(1000)]: {
                marginTop: '0px',
                marginBottom: '10px',
                flexDirection: 'column-reverse',
                '& #rollover': {
                    height: '575px',
                    width: '100%',
                    '& img': {
                        width: '74.8% !important',
                        position: 'absolute',
                        top: '2060px',
                        objectFit: 'contain !important',
                    },
                },
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '80px',
                marginTop: '100px',
                '& #rollover': {
                    width: '77%',
                    '& img': {
                        top: '2450px',
                        left: '-1vw',
                    },
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '-8rem',
                '& #rollover': {
                    '& img': { top: '2310px' },
                },
            },
            [theme.breakpoints.down(551)]: {
                '& #rollover': {
                    '& img': {
                        top: '2190px',
                        height: '870px !important',
                    },
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #rollover': {
                    width: '100%',
                    '& img': {
                        height: '540px !important',
                        top: '2060px',
                    },
                },
            },
            [theme.breakpoints.down(451)]: {
                '& #rollover': {
                    '& img': {
                        height: '600px !important',
                    },
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '-7rem',
                '& #rollover': {
                    '& img': {
                        top: '1800px',
                        height: '450px !important',
                    },
                },
            },
        },
        boxes: {
            [theme.breakpoints.down(1000)]: {
                position: 'absolute',
                top: '2830px',
                right: '14vw',
            },
            [theme.breakpoints.down(769)]: {
                top: '3180px',
                right: '4vw',
            },
            [theme.breakpoints.down(651)]: {
                top: '2990px',
                width: '70%',
            },
            [theme.breakpoints.down(551)]: { top: '2830px' },
            [theme.breakpoints.down(501)]: { top: '2530px' },
            [theme.breakpoints.down(401)]: { top: '2175px' },
        },
        textContainer: {
            width: '425px',
            marginBottom: '30px',
            marginLeft: '13.89%',
            marginTop: '15px',
            '& #text': {
                marginBottom: '130px',
                fontSize: '18.3px',
                letterSpacing: '0.7px',
                color: '#3D4274',
                lineHeight: '26.35px',
                width: '323px',
            },
            [theme.breakpoints.down(1300)]: {
                marginLeft: '7%',
            },
            [theme.breakpoints.down(1100)]: {
                marginLeft: '5%',
            },
            [theme.breakpoints.down(1000)]: {
                marginLeft: '60%',
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '40%',
                margin: '0px 0px 0px 22%',
                '& #text': {
                    marginLeft: '22%',
                    marginBottom: '180px',
                },
            },
            [theme.breakpoints.down(751)]: {
                marginLeft: '35%',
                '& #text': {
                    fontSize: '16px',
                    marginBottom: '150px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& #text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                    marginBottom: '120px',
                    width: '73%',
                    marginLeft: '17%',
                },
            },
            [theme.breakpoints.down(600)]: {
                '& #text': {
                    width: '68%',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& #text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                    marginBottom: '100px',
                    width: '62%',
                    marginLeft: '12%',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& #text': {
                    fontSize: '11px',
                    lineHeight: '18px',
                    marginBottom: '90px',
                    width: '58%',
                },
            },
            [theme.breakpoints.down(451)]: {
                '& #text': {
                    width: '50%',
                },
            },
            [theme.breakpoints.down(401)]: {
                '& #text': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                    marginBottom: '69px',
                    width: '48%',
                    marginLeft: '5%',
                },
            },
            [theme.breakpoints.down(351)]: {
                '& #text': {
                    width: '45%',
                    marginLeft: '4%',
                },
            },
        },
        dynamicBoxes: {
            marginTop: '10px',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '10px',
            '& div': {
                cursor: 'pointer',
                height: '100px',
                backgroundColor: 'rgba(61, 66, 116, 0.07)',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            },
            '& .clicked': {
                background: '#3D4274 !important',
            },
            [theme.breakpoints.down(1000)]: {
                gridGap: '30px',
                '& div': { height: '115px' },
            },
            [theme.breakpoints.down(769)]: { gridGap: '20px' },
            [theme.breakpoints.down(651)]: { '& div': { height: '100px' } },
            [theme.breakpoints.down(600)]: { '& div': { height: '85px' } },
            [theme.breakpoints.down(501)]: { '& div': { height: '70px' } },
            [theme.breakpoints.down(451)]: { '& div': { height: '55px' } },
            [theme.breakpoints.down(401)]: { '& div': { height: '50px' } },
            [theme.breakpoints.down(381)]: { '& div': { height: '46px' } },
            [theme.breakpoints.down(341)]: { '& div': { height: '38px' } },
        },
        '& .one': {
            gridColumn: 1 / 4,
        },
        '& .two': {
            gridColumn: 2 / 4,
        },
        '& .three': {
            gridColumn: 3 / 4,
        },
        '& .four': {
            gridColumn: 4 / 4,
        },
        titleContainer: {
            marginBottom: '120.65px',
            '& #botTitle': {
                fontSize: '32px',
                fontWeight: 500,
                lineHeight: '48px',
                letterSpacing: '2.86px',
                marginBottom: '0',
            },
            '& #title': {
                fontSize: '36px',
                fontWeight: 900,
                lineHeight: '48px',
                letterSpacing: '3.21px',
                '& #top': {
                    marginBottom: '0',
                },
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '22%',
                marginBottom: '90px',
                '& #title': {
                    lineHeight: '42px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& #botTitle': {
                    fontSize: '29px',
                },
                '& #title': {
                    fontSize: '32px',
                    lineHeight: '40px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginLeft: '17%',
                '& #botTitle': {
                    fontSize: '26px',
                },
                '& #title': {
                    fontSize: '29px',
                    lineHeight: '37px',
                },
            },
            [theme.breakpoints.down(601)]: {
                '& #title': {
                    lineHeight: '34px',
                },
            },
            [theme.breakpoints.down(551)]: {
                marginLeft: '12%',
                '& #botTitle': {
                    fontSize: '22px',
                },
                '& #title': {
                    fontSize: '25px',
                    lineHeight: '31px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '70px',
                '& #botTitle': {
                    fontSize: '20px',
                },
                '& #title': {
                    fontSize: '23px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(451)]: {
                '& #botTitle': {
                    fontSize: '18px',
                },
                '& #title': {
                    fontSize: '21px',
                    lineHeight: '25px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginLeft: '5%',
                marginBottom: '62px',
                '& #botTitle': {
                    fontSize: '16px',
                },
                '& #title': {
                    fontSize: '19.43',
                    lineHeight: '22px',
                },
            },
        },
    });
