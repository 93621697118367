// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// local imports
import { styles } from './Savings.style';
import Hero from './Hero';
import Goals from './Goals';
import Benefits from './Benefits';
import Control from './Control';
import FirstAppRow from './FirstAppRow';
import SecondAppRow from './SecondAppRow';
import SaveMoney from './SaveMoney';
import SavingsExplain from './SavingsExplain';
import SavingsBenefits from './SavingsBenefits';
import SavingsCalculator from './SavingsCalculator';

function Savings(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Hero />
            <Benefits />
            <SaveMoney />
            <SavingsCalculator />
            <Control />
            <FirstAppRow />
            <SavingsExplain />
            <SecondAppRow />
            <Goals />
            <SavingsBenefits />
        </Box>
    );
}

export default withStyles(styles)(Savings);
