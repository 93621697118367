// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { styles } from './SavingsExplain.style';

function SavingsExplain(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <p className="text">
                    Tienes acceso a tus fondos en todo momento, y en cuanto quieras usarlos, puedes transferirlos de tus
                    apartados al saldo principal.
                </p>
            </Box>
            <Box
                className={classes.gridContainer}
                style={{
                    borderRadius: '1px',
                    borderTop: '1px',
                    borderLeft: '1px',
                    borderBottom: '1px',
                    borderRight: '0px',
                    borderColor: '#3D4274',
                    borderStyle: 'solid',
                    backgroundColor: '#ffffff',
                }}
            >
                <Box className={classes.textContainer1}>
                    <p className="subtitle">CREA UN</p>
                    <p className="subtitle">APARTADO</p>
                    <p className="subtitle">EN</p>
                    <p className={classes.bigText}>3</p>
                    <p className="subtitle">PASOS</p>
                </Box>
                <Box className={classes.textContainer2}>
                    <p className="text">Entra a “APARTADOS” desde el menú principal de tu app</p>
                    <p className={classes.straightLine} />
                    <p className="text">Elige la imagen que quieras para identificar tu apartado.</p>
                    <p className={classes.straightLine} />
                    <p className="text">Dale un nombre a tu apartado y ¡listo, ya creaste un apartado!</p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(SavingsExplain);
