import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        hero: {
            fontFamily: 'MuseoSans, sans-serif',
            marginTop: '180px',
            height: '380px',
            width: '100%',
            marginBottom: '380px',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            '& img': {
                height: '150%',
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1880)]: { height: '600px' },
            [theme.breakpoints.up(1700)]: { height: '500px' },
            [theme.breakpoints.up(1500)]: { height: '550px' },
            [theme.breakpoints.up(1300)]: { height: '400px' },
            [theme.breakpoints.down(900)]: {
                height: '300px',
                marginBottom: '400px',
            },
            [theme.breakpoints.down(769)]: {
                height: '100%',
                display: 'flex',
                marginBottom: '0px',
                flexDirection: 'column-reverse',
                '& img': {
                    height: '100%',
                },
            },
        },
        firstStep: {
            marginLeft: '4vw',
            [theme.breakpoints.down(401)]: {
                marginLeft: '7vw',
            },
        },
        lastStep: {
            marginLeft: '11vw',
            [theme.breakpoints.down(401)]: {
                marginLeft: '18vw',
            },
        },
        title: {
            gridColumn: '2 / 2',
            marginLeft: '19vw',
            fontSize: '34px',
            fontWeight: 500,
            color: '#3D4274',
            lineHeight: '41px',
            letterSpacing: '2px',
            [theme.breakpoints.down(769)]: {
                position: 'initial',
                alignSelf: 'center',
                marginBottom: '150px',
                marginLeft: '45%',
                marginTop: '0px !important',
                '& p': {
                    marginBottom: '0px',
                },
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '29px',
                marginBottom: '120px',
            },
            [theme.breakpoints.down(701)]: {
                marginTop: '2% !important',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '24px',
                lineHeight: '45px',
                marginTop: '4% !important',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '22px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '20px',
                lineHeight: '40px',
                marginBottom: '110px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '18px',
                marginBottom: '100px',
                lineHeight: '21.6px',
                letterSpacing: '2px',
            },
        },
        bigger: {
            fontSize: '90px',
            fontWeight: 900,
            lineHeight: '99px',
            letterSpacing: '7px',
            [theme.breakpoints.down(769)]: {
                lineHeight: '100px',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '80px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '70px',
                lineHeight: '95px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '50px',
                lineHeight: '75px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '45px',
                lineHeight: '50px',
                letterSpacing: '3.5px',
            },
        },
    });
