// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import logoUnion from 'images/logos_union.svg';

import { styles } from './Goals.style';

function Goals(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <p id="subtitle">Cumple</p>
                    <p id="subtitle1">TUS METAS</p>
                </Box>
                <p className={classes.topText}>
                    Tus metas son logrables porque cada peso suma, no hay apartados pequeños. Dinero apartado es dinero
                    con el potencial de convertirse en un teléfono nuevo, un viaje con amigues o tu retiro. Apartando
                    logras metas y cumples sueños.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Goals);
