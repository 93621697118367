import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        imgContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0px',
            marginTop: '200px',
            marginLeft: '33%',
            marginRight: '5%',
            '& img': {
                marginRight: '7%',
            },
            [theme.breakpoints.down(1250)]: {
                marginLeft: '15%',
            },
            [theme.breakpoints.down(970)]: {
                width: '25%',
                '& img': {
                    marginRight: '14%',
                },
            },
        },
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            margin: '100px 20% 150px 25% ',
            display: 'block',
            gridTemplateColumns: '37% 63%',
            gridTemplateRows: 'auto auto auto auto auto',
            '& #dots-undefined': {
                position: 'absolute',
                left: '20vw',
                bottom: '7vh',
                zIndex: 1,
            },
            [theme.breakpoints.down(701)]: {
                '& #dots-undefined': { left: '12vw' },
            },
            [theme.breakpoints.down(601)]: {
                margin: '100px 20% 150px 23% ',
                '& #dots-undefined': { left: '15vw' },
            },
            [theme.breakpoints.down(550)]: {
                margin: '100px 20% 100px 23% ',
                '& #dots-undefined': { left: '12vw' },
            },
            [theme.breakpoints.down(500)]: {
                display: 'flex',
                flexDirection: 'column',
                margin: '0px 10% 80px 23% ',
                '& #dots-undefined': { left: '13vw' },
            },
            [theme.breakpoints.down(400)]: {
                margin: '0px 5% 50px 23% ',
                '& #dots-undefined': {
                    left: '13vw',
                },
            },
        },
        carousel: {
            marginRight: '27%',
            overflow: 'hidden',
            height: '700px',
            [theme.breakpoints.down(1101)]: {
                marginRight: '21%',
            },
            [theme.breakpoints.down(1001)]: {
                marginRight: '10%',
            },
            [theme.breakpoints.down(850)]: {
                height: '640px',
                marginRight: '0%',
            },
            [theme.breakpoints.down(701)]: {
                height: '690px',
                marginTop: '20%',
            },
            [theme.breakpoints.down(550)]: {
                height: '590px',
            },
            [theme.breakpoints.down(450)]: {
                height: '530px',
            },
            [theme.breakpoints.down(400)]: {
                height: '475px',
            },
            [theme.breakpoints.down(350)]: {
                height: '400px',
            },
        },
    });
