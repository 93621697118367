// React imports
import React from 'react';
import PropTypes from 'prop-types';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

// local imports
import { styles } from './Dropdown.style';
import arrowDown from './images/arrowDown.svg';
import arrowUp from './images/arrowUp.svg';
import check from './images/check.svg';

type DropdownProps = typeof Dropdown.defaultProps & {
    isListOpen: isListOpen;
    selectedItem: null;
    title;
    list;
};

class Dropdown extends React.Component<WithStyles<typeof styles> & DropdownProps> {
    static defaultProps = {
        id: '',
        select: undefined,
        styles: {},
        arrowUpIcon: null,
        arrowDownIcon: null,
        checkIcon: null,
    };

    constructor(props) {
        super(props);
        const { title, list, classes } = this.props;

        this.state = {
            isListOpen: false,
            title,
            selectedItem: null,
            keyword: '',
            list,
        };

        this.searchField = React.createRef();
    }

    componentDidMount() {
        const { select } = this.props;

        if (select) {
            this.selectSingleItem(select);
        }
    }

    componentDidUpdate() {
        const { isListOpen } = this.state;

        setTimeout(() => {
            if (isListOpen) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.close);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { list } = nextProps;

        if (JSON.stringify(list) !== JSON.stringify(prevState.list)) {
            return { list };
        }

        return null;
    }

    close = () => {
        this.setState({
            isListOpen: false,
        });
    };

    clearSelection = () => {
        const { name, title, onChange } = this.props;

        this.setState(
            {
                selectedItem: null,
                title,
            },
            () => {
                onChange(null, name);
            },
        );
    };

    selectSingleItem = item => {
        const { list } = this.props;

        const selectedItem = list.find(i => i.value === item.value);
        this.selectItem(selectedItem);
    };

    selectItem = item => {
        const { label, value } = item;
        const { list, selectedItem } = this.state;
        const { name, onChange } = this.props;

        let foundItem;

        if (!label) {
            foundItem = list.find(i => i.value === item.value);
        }

        this.setState(
            {
                title: label || foundItem.label,
                isListOpen: false,
                selectedItem: item,
            },
            () => selectedItem?.value !== value && onChange(item, name),
        );
    };

    toggleList = () => {
        this.setState(
            prevState => ({
                isListOpen: !prevState.isListOpen,
                keyword: '',
            }),
            () => {
                if (this.state.isListOpen && this.searchField.current) {
                    this.searchField.current.focus();
                    this.setState({
                        keyword: '',
                    });
                }
            },
        );
    };

    listItems = () => {
        const { id, searchable, checkIcon, styles, classes } = this.props;
        const { listItem, listItemNoResult } = styles;
        const { keyword, list } = this.state;
        let tempList = [...list];
        const selectedItemValue = this.state.selectedItem?.value;

        if (keyword.length) {
            tempList = list.filter(item => item.label.toLowerCase().includes(keyword.toLowerCase()));
        }

        if (tempList.length) {
            return tempList.map(item => (
                <button
                    type="button"
                    className={classes.dropdownList}
                    style={listItem}
                    key={item.value}
                    onClick={() => this.selectItem(item)}
                >
                    {item.label}{' '}
                    {item.value === selectedItemValue && <span style={styles.checkIcon}>{checkIcon || <check />}</span>}
                </button>
            ));
        }
    };

    render() {
        const { isListOpen, title } = this.state;

        const { id, searchable, arrowUpIcon, arrowDownIcon, styles, classes } = this.props;

        return (
            <div className={`dd-wrapper ${id}`}>
                <button type="button" className={classes.dropdownButton} onClick={this.toggleList}>
                    <div className={clsx(classes.headerTitle, this.state.selectedItem && classes.headerTitleFilled)}>
                        {title}
                    </div>
                    {isListOpen ? (
                        <img src={arrowUpIcon || arrowUp} className={classes.arrow} />
                    ) : (
                        <img src={arrowDownIcon || arrowDown} className={classes.arrow} />
                    )}
                </button>
                {isListOpen && (
                    <div className={`dd-list${searchable ? ' searchable' : ''} ${id}`}>
                        {searchable && (
                            <input
                                ref={this.searchField}
                                className={`dd-list-search-bar ${id}`}
                                placeholder={searchable[0]}
                                onClick={e => e.stopPropagation()}
                                onChange={e => this.filterList(e)}
                            />
                        )}
                        <div className={classes.itemContainer}>{this.listItems()}</div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Dropdown);
