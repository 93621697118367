import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        gridContainer: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '250px',
            marginTop: '250px',
            marginLeft: '30%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            '& #benefits': {
                height: '579px',
                width: '363px',
                '& img': {
                    height: '579px',
                    width: '363px',
                    marginBottom: '0px',
                },
            },
            [theme.breakpoints.down(769)]: {
                flexDirection: 'column',
                marginBottom: '0px',
                marginTop: '207px',
                marginLeft: '37.5%',
                alignItems: 'flex-start',
                '& #benefits': {
                    marginTop: '143px',
                    marginLeft: 'auto',
                    width: '426px',
                    '& img': {
                        width: '426px !important',
                    },
                },
            },
            [theme.breakpoints.down(700)]: {
                '& #benefits': {
                    width: '426px',
                    marginLeft: 'auto',
                    '& img': {
                        width: '356px !important',
                        height: '529px !important',
                    },
                },
            },
            [theme.breakpoints.down(651)]: {
                marginLeft: '32%',
            },
            [theme.breakpoints.down(601)]: {
                '& #benefits': {
                    width: '350px',
                    '& img': {
                        width: '350px !important',
                    },
                },
            },
            [theme.breakpoints.down(551)]: {
                marginLeft: '27%',
            },
            [theme.breakpoints.down(501)]: {
                '& #benefits': {
                    width: '300px',
                    height: '460px',
                    '& img': {
                        width: '275px !important',
                        height: '413px !important',
                    },
                },
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '100px',
                '& #benefits': {
                    marginTop: '80px',
                    width: '224px',
                    height: '500px',
                    '& img': {
                        width: '224px !important',
                        height: '379px !important',
                    },
                },
            },
            [theme.breakpoints.down(351)]: {
                marginLeft: '22%',
            },
        },
        textContainer: {
            width: '48%',
            [theme.breakpoints.down(769)]: {
                width: '81%',
            },
        },
        subtitle: {
            fontSize: '32px',
            color: '#3D4274',
            letterSpacing: '2.86px',
            lineHeight: '48px',
            fontWeight: 500,
            marginBottom: '100px',
            '& span': {
                fontWeight: 900,
                fontSize: '36px',
                letterSpacing: '3.21px',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '29px',
                '& span': {
                    fontSize: '32px',
                },
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '26px',
                lineHeight: '40px',
                '& span': {
                    fontSize: '29px',
                },
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '22px',
                lineHeight: '30px',
                '& span': {
                    fontSize: '25px',
                },
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '20px',
                '& span': {
                    fontSize: '23px',
                },
            },
            [theme.breakpoints.down(451)]: {
                fontSize: '18px',
                '& span': {
                    fontSize: '21px',
                },
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '16px',
                lineHeight: '22.8px',
                letterSpacing: '1.43px',
                marginBottom: '60px',
                '& span': {
                    fontSize: '19.43px',
                    letterSpacing: '1.73px',
                },
            },
        },
        text: {
            fontSize: '18.3px',
            color: '#3D4274',
            letterSpacing: '0.6px',
            lineHeight: '26.35px',
            fontWeight: 500,
            margin: '0',
            [theme.breakpoints.down(1100)]: {
                fontSize: '18px',
            },
            [theme.breakpoints.down(1060)]: {
                fontSize: '17.5px',
            },
            [theme.breakpoints.down(1000)]: {
                fontSize: '17px',
            },
            [theme.breakpoints.down(900)]: {
                fontSize: '16.5px',
            },
            [theme.breakpoints.down(800)]: {
                fontSize: '16.2px',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
                lineHeight: '16px',
                letterSpacing: '0.4px',
            },
        },
        subtext: {
            width: '100%',
            fontSize: '11px',
            fontWeight: 600,
            color: '#A2A5BD',
            letterSpacing: '1px',
            lineHeight: '25px',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
            },
            [theme.breakpoints.down(769)]: {
                fontSize: '21px',
                letterSpacing: '0.75px',
                lineHeight: '30px',
            },
            [theme.breakpoints.down(701)]: {
                width: '84%',
                fontSize: '19px',
                letterSpacing: '0.34px',
                lineHeight: '28px',
            },
            [theme.breakpoints.down(650)]: {
                width: '90%',
                fontSize: '16px',
                letterSpacing: '0.5px',
                lineHeight: '26px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10.86px',
                letterSpacing: '0.45px',
                lineHeight: '16px',
            },
        },
        video: {
            height: '630px',
            width: '460px',
            clear: 'both',
            marginLeft: 'auto',
            '& video': {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.down(740)]: {
                marginTop: '50px',
                height: 'auto',
                width: 'auto',
            },
        },
    });
