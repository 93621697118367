// React imports
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import NumberFormat from 'react-number-format';

// local imports
import { styles } from './SavingsCalculator.style';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

type NavigatorBarProps = {
    showModal: () => void;
} & typeof defaultProps;

const defaultProps = {
    showModal: () => console.log('Please add function to show modal'),
};

const DATA_SOURCE = {
    locations: [
        {
            label: 'DIARIAMENTE',
            value: 'diariamente',
        },
        {
            label: 'SEMANALMENTE',
            value: 'semanalmente',
        },
        {
            label: 'QUINCENALMENTE',
            value: 'quincenalmente',
        },
        {
            label: 'MENSUALMENTE',
            value: 'mensualmente',
        },
        {
            label: 'ANUALMENTE',
            value: 'aanualmente ',
        },
    ],
    locationsConver: {
        DIARIAMENTE: 365,
        SEMANALMENTE: 52,
        QUINCENALMENTE: 24,
        MENSUALMENTE: 12,
        ANUALMENTE: 1,
    },
};

const timeSource = {
    periods: [
        {
            label: '1 AÑO',
            value: 1,
        },
        {
            label: '2 AÑOS',
            value: 2,
        },
        {
            label: '5 AÑOS',
            value: 5,
        },
        {
            label: '10 AÑOS',
            value: 10,
        },
        {
            label: '20 AÑOS',
            value: 20,
        },
    ],
};

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function SavingsCalculator(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense, showModal } = props;
    const { locations, locationsConver } = DATA_SOURCE;
    const { periods } = timeSource;

    const theme = useTheme();
    const breakLine = useMediaQuery(theme.breakpoints.up(1000));

    const [showResults, setShowResults] = useState(true);
    const [frequencyString, setFrequencyString] = useState('');
    const [timeString, setTimeString] = useState('');
    const [amount, setAmount] = useState(0);
    const [totalTime, setTotalTime] = useState('EN ?? AÑOS');
    const [total, setTotal] = useState('???,???,???');
    const calculate = () => {
        if (!amount || !frequencyString || !timeString) return;
        setShowResults(true);
        const totalSaving = amount * locationsConver[frequencyString] * parseInt(timeString);
        setTotal(totalSaving.toLocaleString());
        setTotalTime('EN ' + timeString);
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <p className="text">
                    Comprueba en nuestro tabulador el poder que tienen tus fondos atesorados. Ordena tus finanzas, evita
                    los gastos hormiga y decide tus metas, los montos de los apartados y el tiempo en que deseas
                    cumplirlas.
                </p>
            </Box>
            <Box className={classes.calcContainer}>
                <Box className={classes.firstRow}>
                    <Input
                        className={clsx(classes.inputBox, amount && classes.inputBoxFilled)}
                        disableUnderline={true}
                        placeholder="¿Cuántos pesitos vas a apartar?"
                        onClick={() => setShowResults(false)}
                        inputProps={{ maxLength: 8 }}
                        inputComponent={NumberFormatCustom}
                        onChange={e => setAmount(e.target.value)}
                    />
                    <Button className={classes.calcButton} text="Calcular" onClick={calculate} />
                </Box>
                <Box>
                    <Dropdown
                        name="frecuency"
                        title="¿Con qué frecuencia?"
                        list={locations}
                        onChange={item => setFrequencyString(item.label)}
                    />
                    <Dropdown
                        name="periods"
                        title="Ver mis ahorros acumulados en..."
                        list={periods}
                        onChange={item => setTimeString(item.label)}
                    />
                </Box>
                <Box className={classes.resultContainer} style={{ visibility: showResults ? 'visible' : 'hidden' }}>
                    <p id="subtitle">{breakLine ? `PUEDES\nSUMAR` : `PUEDES SUMAR`}</p>
                    <Box className={classes.lastRow}>
                        <p id="subtitle1">
                            <span id="currency">$</span>
                            {total}
                        </p>
                        <p id="subtitle2" className={classes.withMargin}>
                            {totalTime}
                        </p>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(SavingsCalculator);
