// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { styles } from './GifCarousel.style';

// local imports for travesia

// Clientes actuales
// Fisica (missing Virtual)
import actual_fisica_1 from './images/MC_steps_reemplazo_FISICA_1_B.gif';
import actual_fisica_2 from './images/MC_steps_reemplazo_FISICA_2_B.gif';
import actual_fisica_3 from './images/MC_steps_reemplazo_FISICA_3_B.gif';
import actual_fisica_4 from './images/MC_steps_reemplazo_FISICA_4_B.gif';
import actual_fisica_5 from './images/MC_steps_reemplazo_FISICA_5_B.gif';
import actual_fisica_6 from './images/MC_steps_reemplazo_FISICA_6_B.gif';
import actual_fisica_7 from './images/MC_steps_reemplazo_FISICA_7_B.gif';

// Clientes Nuevos
import nuevo_fisica_1 from './images/MC_steps_nuevos_FISICA_1_B.gif';
import nuevo_fisica_2 from './images/MC_steps_nuevos_FISICA_2_B.gif';
import nuevo_fisica_3 from './images/MC_steps_nuevos_FISICA_3_B.gif';
import nuevo_fisica_4 from './images/MC_steps_nuevos_FISICA_4_B.gif';
import nuevo_fisica_5 from './images/MC_steps_nuevos_FISICA_5_B.gif';
import nuevo_fisica_6 from './images/MC_steps_nuevos_FISICA_6_B.gif';
import nuevo_fisica_7 from './images/MC_steps_nuevos_FISICA_7_B.gif';
import nuevo_fisica_8 from './images/MC_steps_nuevos_FISICA_9_B.gif';

import nuevo_virtual_1 from './images/MC_steps_nuevos_VIRTUAL_1_B.gif';
import nuevo_virtual_2 from './images/MC_steps_nuevos_VIRTUAL_2_B.gif';
import nuevo_virtual_3 from './images/MC_steps_nuevos_VIRTUAL_3_B.gif';
import nuevo_virtual_4 from './images/MC_steps_nuevos_VIRTUAL_4_B.gif';
import nuevo_virtual_5 from './images/MC_steps_nuevos_VIRTUAL_5_B.gif';
import nuevo_virtual_6 from './images/MC_steps_nuevos_VIRTUAL_6_B.gif';

// Cards/CardsCarousel
import tarjeta_fisica_1 from './images/WS_appflows_fisica_1.gif';
import tarjeta_fisica_2 from './images/WS_appflows_fisica_2.gif';
import tarjeta_fisica_3 from './images/WS_appflows_fisica_3.gif';
import tarjeta_fisica_4 from './images/WS_appflows_fisica_4.gif';
import tarjeta_fisica_5 from './images/WS_appflows_fisica_5.gif';
import tarjeta_fisica_6 from './images/WS_appflows_fisica_6.gif';

import tarjeta_virtual_1 from './images/WS_appflows_virtual_1.gif';
import tarjeta_virtual_2 from './images/WS_appflows_virtual_2.gif';
import tarjeta_virtual_3 from './images/WS_appflows_virtual_3.gif';
import tarjeta_virtual_4 from './images/WS_appflows_virtual_4.gif';
import tarjeta_virtual_5 from './images/WS_appflows_virtual_5.gif';
import tarjeta_virtual_6 from './images/WS_appflows_virtual_6.gif';

// Savings Responsive Carousel
import firstSavingImage from 'images/svg/first-step-apartados.svg';
import secondSavingImage from 'images/svg/second-step-apartados.svg';
import thirdSavingImage from 'images/svg/third-step-apartados.svg';
import fourthSavingImage from 'images/svg/fourth-step-apartados.svg';
import fifthSavingImage from 'images/svg/fifth-step-apartados.svg';
import sixthSavingImage from 'images/svg/sixth-step-apartados.svg';

type ImageCarouselProps = {
    type: string;
    style: object;
} & typeof defaultProps;

const defaultProps = {
    type: 'current_fisical_1',
};

const DATA_SOURCE = {
    actual_fisica_1: [
        actual_fisica_1,
        'CONOCE ACERCA DE TARJETAS CUENCA Y LOS BENEFICIOS QUE OFRECE TU NUEVA TARJETA FÍSICA.',
    ],
    actual_fisica_2: [
        actual_fisica_2,
        'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA. ',
    ],
    actual_fisica_3: [
        actual_fisica_3,
        'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.',
    ],
    actual_fisica_4: [actual_fisica_4, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    actual_fisica_5: [
        actual_fisica_5,
        'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.',
    ],
    actual_fisica_6: [
        actual_fisica_6,
        'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.',
    ],
    actual_fisica_7: [
        actual_fisica_7,
        '¡LISTO! COMPLETASTE TU SOLICITUD. RECUERDA, PUEDE TARDAR HASTA 7 DÍAS HÁBILES EN LLEGAR.',
    ],

    nuevo_fisica_1: [nuevo_fisica_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    nuevo_fisica_2: [nuevo_fisica_2, 'CONOCE LOS BENEFICIOS QUE OFRECE TU TARJETA FÍSICA'],
    nuevo_fisica_3: [
        nuevo_fisica_3,
        'SELECCIONA UNO DE LOS DOS DISEÑOS DE TARJETA. USA EL CÍRCULO EN LA IMAGEN PARA MARCAR TU PREFERENCIA. ',
    ],
    nuevo_fisica_4: [
        nuevo_fisica_4,
        'CAPTURA Y REVISA LA INFORMACIÓN PARA LA ENTREGA. SEÑALA LA UBICACIÓN A LA QUE ENVIAREMOS TU TARJETA.',
    ],
    nuevo_fisica_5: [nuevo_fisica_5, 'VERIFICA QUE LOS DATOS SEAN CORRECTOS, MODIFÍCALOS EN CASO DE QUE NO LO SEAN.'],
    nuevo_fisica_6: [
        nuevo_fisica_6,
        'PUEDES DARNOS INSTRUCCIONES ESPECÍFICAS PARA TU ENTREGA ANTES DE FINALIZAR TU SOLICITUD.',
    ],
    nuevo_fisica_7: [nuevo_fisica_7, 'SELECCIONA EL HORARIO EN EL QUE DESEAS RECIBIR TU TARJETA.'],
    nuevo_fisica_8: [
        nuevo_fisica_8,
        '¡LISTO! COMPLETASTE TU SOLICITUD. RECIBIRÁS UNA NOTIFICACIÓN CUANDO ESTÉ EN CAMINO.',
    ],

    nuevo_virtual_1: [
        nuevo_virtual_1,
        'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP. CONOCE ACERCA DE NUESTRA NUEVA ALIANZA Y CONTINÚA.',
    ],
    nuevo_virtual_2: [
        nuevo_virtual_2,
        'SELECCIONA EL TIPO DE TARJETA VIRTUAL QUE DESEAS TENER. RECUERDA QUE NO PODRÁS CAMBIARLA UNA VEZ SOLICITADA.',
    ],
    nuevo_virtual_3: [nuevo_virtual_3, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    nuevo_virtual_4: [nuevo_virtual_4, 'CONOCE ACERCA DE TARJETAS CUENCA.'],
    nuevo_virtual_5: [
        nuevo_virtual_5,
        'LEE CON CUIDADO LOS DOCUMENTOS DESCARGABLES. DEBES MARCAR LOS DOS APARTADOS PARA CONTINUAR CON TU SOLICITUD.',
    ],
    nuevo_virtual_6: [
        nuevo_virtual_6,
        '¡LISTO! YA PUEDES USAR TU TARJETA VIRTUAL. SIEMPRE PODRÁS CONSULTAR TUS DATOS DESDE LA APP.',
    ],

    tarjeta_virtual_1: [tarjeta_virtual_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    tarjeta_virtual_2: [tarjeta_virtual_2, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    tarjeta_virtual_3: [tarjeta_virtual_3, 'PARA REALIZAR TU PAGO NECESITAS COPIAR TU NÚMERO DE TARJETA.'],
    tarjeta_virtual_4: [
        tarjeta_virtual_4,
        'RECUERDA QUE TAMBIÉN TE VAN A SOLICITAR LA FECHA DE EXPIRACIÓN DE TU TARJETA Y EL CÓDIGO DE VERIFICACIÓN.',
    ],
    tarjeta_virtual_5: [
        tarjeta_virtual_5,
        'PUEDES CONSULTAR RÁPIDAMENTE LAS TRANACCIONES QUE HAS REALIZADO CON ESTA TARJETA.',
    ],
    tarjeta_virtual_6: [tarjeta_virtual_6, 'TAMBIÉN BLOQUEARLA Y DESBLOQUEARLA FÁCILMENTE.'],

    tarjeta_fisica_1: [tarjeta_fisica_1, 'ENTRA A “TUS TARJETAS” DESDE LA PANTALLA PRINCIPAL DE LA APP.'],
    tarjeta_fisica_2: [tarjeta_fisica_2, 'ESPERA UN MOMENTO, TE REDIRECCIONAREMOS A TARJETAS CUENCA.'],
    tarjeta_fisica_3: [tarjeta_fisica_3, 'ENTRA A LA SECCIÓN “TARJETA FÍSICA”.'],
    tarjeta_fisica_4: [
        tarjeta_fisica_4,
        'PODRÁS CONSULTAR TU NIP EN CUALQUIER MOMENTO DESDE TU APP. ENTRA A “MI NIP” PARA VERLO',
    ],
    tarjeta_fisica_5: [tarjeta_fisica_5, 'POR SEGURIDAD, TE PEDIREMOS TU CONTRASEÑA ANTES DE MOSTRARTE TU NIP.'],
    tarjeta_fisica_6: [
        tarjeta_fisica_6,
        '¡LISTO! USA EL NIP PARA HACER COMPRAS CON TU TARJETA FÍSICA. RECUERDA, EL NIP ES PERSONAL Y NO SE PUEDE CAMBIAR.',
    ],

    first_saving_image: [firstSavingImage, ''],
    second_saving_image: [secondSavingImage, ''],
    third_saving_image: [thirdSavingImage, ''],
    fourth_saving_image: [fourthSavingImage, ''],
    fifth_saving_image: [fifthSavingImage, ''],
    sixth_saving_image: [sixthSavingImage, ''],
};

function GifCarousel(props: ImageCarouselProps & WithStyles<typeof styles>) {
    const { classes, type, ...rest } = props;
    const data = DATA_SOURCE[type];

    return (
        <Box className={classes.root} {...rest}>
            <Box className={classes.image}>
                <img src={data[0]} alt="Random Image Placeholder" />
            </Box>

            <Box className={classes.description}>{data[1]}</Box>
        </Box>
    );
}
GifCarousel.defaultProps = defaultProps;

export default withStyles(styles)(GifCarousel);
