// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Carousel from 'components/Carousel';
import GifCarousel from 'components/GifCarousel';

// local imports
import firstImage from 'images/svg/first-step-apartados.svg';
import secondImage from 'images/svg/second-step-apartados.svg';
import thirdImage from 'images/svg/third-step-apartados.svg';
import { styles } from './FirstAppRow.style';

function FirstAppRow(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const responsive = useMediaQuery('(min-width:769px)');
    let imagesData = [
        style => <GifCarousel type="first_saving_image" style={style} />,
        style => <GifCarousel type="second_saving_image" style={style} />,
        style => <GifCarousel type="third_saving_image" style={style} />,
    ];

    return responsive ? (
        <Box className={classes.imgContainer}>
            <img src={firstImage} alt="Pasos para crear apartados" />
            <img src={secondImage} alt="Pasos para crear apartados" />
            <img src={thirdImage} alt="Pasos para crear apartados" />
        </Box>
    ) : (
        <Box className={classes.root}>
            <Box className={classes.carousel}>
                <Carousel dataPerPage={imagesData.length} carouselSlides={imagesData} />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(FirstAppRow);
