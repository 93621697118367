// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

// local imports
import { styles } from './Hero.style';

function Hero(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.hero}>
            <Box className={classes.title}>
                <p className={classes.bigger}>A</p>
                <p className={clsx(classes.bigger, classes.firstStep)}>PAR</p>
                <p className={clsx(classes.bigger, classes.lastStep)}>TA</p>
                <p className={clsx(classes.bigger, classes.lastStep)}>DOS</p>
                <p className={classes.lastStep}>pero juntos</p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Hero);
