import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            margin: '390px 0 17em 28% ',
            display: 'grid',
            gridTemplateColumns: '27% 83%',
            gridTemplateRows: 'auto auto auto auto auto',
            [theme.breakpoints.down(769)]: {
                display: 'flex',
                flexDirection: 'column-reverse',
                margin: '110px 0 0px 22%',
            },
            [theme.breakpoints.down(769)]: {
                display: 'flex',
                flexDirection: 'column-reverse',
                margin: '110px 0 0px 22%',
            },
            [theme.breakpoints.down(551)]: {
                margin: '-100px 0 0px 22%',
            },
        },
        gridContainer: {
            margin: '0px 0px 0px 170px',
            padding: '73px 110px 90px 70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down(1200)]: {
                margin: '0px 0px 250px 20%',
                padding: '73px 58px 90px 40px',
            },
            [theme.breakpoints.down(769)]: {
                margin: ' 123px 0px 100px 0px',
                padding: '57px 0px 53px 65px',
            },
            [theme.breakpoints.down(551)]: {
                padding: '48px 0px 40px 49px',
                margin: ' 80px 0px 100px 0px',
            },
            [theme.breakpoints.down(401)]: {
                padding: '40px 0px 35px 43px',
                margin: ' 55px 0px 80px 0px',
            },
            [theme.breakpoints.down(356)]: {
                padding: '40px 0px 35px 23px',
            },
        },
        textContainer1: {
            width: '20%',
            alignSelf: 'center',
            color: '#3D4274',
            '& .subtitle': {
                fontSize: '19px',
                fontWeight: 700,
                letterSpacing: '0.89px',
                lineHeight: '29px',
                margin: '0',
            },
            [theme.breakpoints.down(769)]: {
                alignSelf: 'auto',
            },
            [theme.breakpoints.down(769)]: {
                alignSelf: 'auto',
            },
            [theme.breakpoints.down(751)]: {
                '& .subtitle': {
                    fontSize: '17px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .subtitle': {
                    fontSize: '15px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& .subtitle': {
                    fontSize: '13px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .subtitle': {
                    fontSize: '11px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(401)]: {
                '& .subtitle': {
                    fontSize: '9.14px',
                    lineHeight: '14.4px',
                },
            },
        },
        textContainer: {
            fontSize: '32px',
            color: theme.palette.primary.main,
            letterSpacing: '0.89px',
            lineHeight: '50px',
            fontWeight: 500,
            marginBottom: '0px',
            display: 'flex',
            alignItems: 'center',
            '& .text': {
                fontSize: '18.3px',
                letterSpacing: '0.7px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(769)]: {
                maxWidth: '65%',
                '& .text': {
                    marginBottom: '0px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .text': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& .text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                maxWidth: '70%',
                '& .text': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
            },
            [theme.breakpoints.down(451)]: {
                fontSize: '18px',
            },
            [theme.breakpoints.down(401)]: {
                '& .text': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                },
            },
        },
        textContainer2: {
            width: '70%',
            alignSelf: 'center',
            color: '#3D4274',
            '& .text': {
                fontSize: '18.33px',
                letterSpacing: '0.7px',
                lineHeight: '26.35px',
                paddingRight: '60px',
            },
            [theme.breakpoints.down(769)]: {
                '& .text': {
                    paddingRight: '0px',
                    maxWidth: '75%',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .text': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& .text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .text': {
                    fontSize: '11px',
                    lineHeight: '18px',
                    maxWidth: '90%',
                },
            },
            [theme.breakpoints.down(401)]: {
                '& .text': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                    marginBottom: '0px',
                },
            },
        },
        bigText: {
            fontSize: '120px',
            color: '#3D4274',
            letterSpacing: '0.75px',
            fontWeight: 900,
            marginBottom: '0px',
            lineHeight: '105px',
            [theme.breakpoints.down(751)]: {
                fontSize: '110px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '100px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '90px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '80px',
            },
            [theme.breakpoints.down(451)]: {
                fontSize: '75px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '68.57px',
            },
        },
        straightLine: {
            borderTop: '1px solid #3D4274',
            background: 'none !important',
            margin: '25px  0',
            [theme.breakpoints.down(401)]: {
                margin: '15px  0',
            },
        },
    });
