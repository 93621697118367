import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            margin: '190px 0 17em 28% ',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gridTemplateColumns: '27% 83%',
            gridTemplateRows: 'auto auto auto auto auto',
            [theme.breakpoints.down(1000)]: {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
            },
            [theme.breakpoints.down(700)]: {
                marginTop: '150px',
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '140px',
            },
        },
        textContainer: {
            fontSize: '32px',
            color: theme.palette.primary.main,
            letterSpacing: '0.89px',
            lineHeight: '50px',
            fontWeight: 500,
            marginBottom: '0px',
            display: 'flex',
            alignItems: 'flex-start',
            '& .text': {
                fontSize: '18.3px',
                letterSpacing: '0.7px',
                lineHeight: '26.35px',
            },
            [theme.breakpoints.down(1000)]: {
                marginRight: '36vw',
            },
            [theme.breakpoints.down(769)]: {
                maxWidth: '65%',
                marginRight: '0px',
                '& .text': {
                    marginBottom: '50px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .text': {
                    fontSize: '16px',
                    marginBottom: '40px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& .text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .text': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
            },
            [theme.breakpoints.down(451)]: {
                fontSize: '18px',
            },
            [theme.breakpoints.down(401)]: {
                '& .text': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                },
            },
        },
        inputBox: {
            fontFamily: 'MuseoSans, sans-serif',
            fontSize: '13px',
            fontWeight: '500',
            fontSize: '13px',
            width: '296px',
            height: '41px',
            fontSize: '13px',
            opacity: '1 !important',
            padding: '0px 0px 0px 11px',
            letterSpacing: '0.4px',
            color: '#3D4274',
            background: '#F1F2F5',
            lineHeight: '26px',
            '& input': {
                height: 'auto',
            },
            '& input:placeholder-shown': {
                color: 'rgba(61, 66, 116, 0.6)',
            },
            [theme.breakpoints.down(421)]: {
                fontSize: '10px',
                width: '225px',
                height: '28px',
            },
        },
        inputBoxFilled: {
            color: 'rgba(61, 66, 116, 1) !important',
        },
        calcContainer: {
            margin: '10px 40px 20px 210px',
            height: '90%',
            width: '60%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.down(1400)]: {
                marginLeft: '180px',
            },
            [theme.breakpoints.down(1300)]: {
                marginLeft: '140px',
            },
            [theme.breakpoints.down(1200)]: {
                marginLeft: '120px',
            },
            [theme.breakpoints.down(1150)]: {
                marginLeft: '100px',
            },
            [theme.breakpoints.down(1100)]: {
                marginLeft: '80px',
            },
            [theme.breakpoints.down(1050)]: {
                marginLeft: '60px',
            },
            [theme.breakpoints.down(1000)]: {
                marginLeft: '0px',
            },
        },
        firstRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
            },
        },
        lastRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        calcButton: {
            maxWidth: '197px',
            minWidth: '91px',
            maxHeight: '24px',
            marginLeft: '40px',
            '& span': {
                marginLeft: '26px',
                marginRight: '26px',
                [theme.breakpoints.down(1200)]: {
                    marginLeft: '16px',
                    marginRight: '16px',
                },
                [theme.breakpoints.down(1150)]: {
                    marginLeft: '6px',
                    marginRight: '6px',
                },
                [theme.breakpoints.down(1100)]: {
                    marginLeft: '0',
                    marginRight: '0',
                },
            },
            [theme.breakpoints.down(1100)]: {
                marginLeft: '20px',
            },
            [theme.breakpoints.down(1050)]: {
                marginLeft: '10px',
            },
            [theme.breakpoints.down(1000)]: {
                position: 'absolute',
                marginLeft: '0px',
                marginTop: '200px',
            },
        },
        frecuency: {
            fontSize: '13.3px',
            lineHeight: '26px',
            letterSpacing: '0.4px',
            color: 'rgba(61, 66, 116, 0.3)',
        },
        resultContainer: {
            marginTop: '40px',
            fontSize: '19px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '1.7px',
            lineHeight: '27px',
            '& #subtitle': {
                marginBottom: '11px',
                marginTop: '35px',
                whiteSpace: 'pre-line',
                fontWeight: 600,
                [theme.breakpoints.down(1000)]: {
                    fontSize: '12px',
                },
            },
            '& #subtitle1': {
                marginBottom: '0px',
                fontWeight: 900,
                fontSize: '56px',
                lineHeight: '65.63px',
            },
            '& #subtitle2': {
                marginBottom: '0px',
                fontWeight: 600,
                marginLeft: '1.813rem',
            },
            '& #currency': {
                fontSize: '40px',
                lineHeight: '48px',
            },
            [theme.breakpoints.down(1000)]: {
                marginTop: '80px',
                '& #subtitle2': {
                    fontSize: '15px',
                },
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '110px',
                '& #subtitle1': {
                    lineHeight: '42px',
                },
                '& #subtitle2': {
                    fontSize: '18px',
                    lineHeight: '27px',
                },
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '90px',
                fontSize: '29px',
                '& #subtitle1': {
                    lineHeight: '40px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '80px',
                fontSize: '26px',
                '& #subtitle1': {
                    fontSize: '29px',
                    lineHeight: '37px',
                },
                '& #currency': {
                    fontSize: '22px',
                },
            },
            [theme.breakpoints.down(601)]: {
                '& #subtitle1': {
                    lineHeight: '34px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& #subtitle1': {
                    lineHeight: '31px',
                },
                '& #subtitle2': {
                    fontSize: '12px',
                    lineHeight: '17px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '70px',
                fontSize: '22px',
                '& #subtitle1': {
                    fontSize: '25px',
                    lineHeight: '28px',
                },
                '& #currency': {
                    fontSize: '19px',
                },
            },
            [theme.breakpoints.down(451)]: {
                marginBottom: '60px',
                fontSize: '18px',
                '& #subtitle1': {
                    fontSize: '21px',
                    lineHeight: '25px',
                },
                '& #currency': {
                    fontSize: '17px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '50px',
                fontSize: '16px',
                '& #subtitle1': {
                    fontSize: '19.43px',
                    lineHeight: '22.8px',
                },
                '& #currency': {
                    fontSize: '15px',
                },
            },
        },
    });
