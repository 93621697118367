// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Benefits.style';

function Benefits(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.gridContainer}>
            <Box className={classes.textContainer}>
                <p className={classes.subtitle}>
                    <span>APARTA</span>
                    <br />
                    con Cuenca
                </p>
                <p className={classes.text}>
                    Administra tus fondos y logra tus metas en Cuenca con Apartados. Al atesorar tu dinero creas una
                    semilla para el futuro; sueña y cosecha más adelante.
                </p>
                <br />
                <p className={classes.text}>
                    Apartados no tiene un costo adicional, úsalo desde tu app. El dinero en tus apartados está separado
                    del saldo principal y no generará ningún rendimiento. Las finanzas personales se transforman para
                    hacerte la vida más fácil. Accede a tus recursos en tus apartados transfiriéndolos de vuelta a tu
                    saldo principal.
                </p>
                <br />
                <p className={classes.subtext}>
                    *El producto Apartados no es un ahorro en términos de los dispuesto por el artículo 105 de la Ley de
                    Instituciones de Crédito, ni por ello se debe inferir que Cuenca presta el servicio de banca y
                    crédito. Apartados es una forma de administrar tus fondos de pago electrónico y realizar tus gastos
                    de forma ordenada.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Benefits);
