import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '25px',
            boxShadow: 'none',
            color: '#FFFFFF',
            fontFamily: 'MuseoSans, sans-serif',
            fontSize: '14.89px',
            fontWeight: 900,
            letterSpacing: '2.5px',
            maxWidth: '348.5px',
            maxHeight: '39.45px',
            minWidth: '235.5px',
            minHeight: '34.7px',
            '& #icon': { marginLeft: '81.15px' },
            '&:active': {
                backgroundColor: '#A5DCD7',
                boxShadow: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                boxShadow: 'none',
            },
        },
        dropdownButton: {
            width: '296px',
            color: 'rgba(61, 66, 116, 0.3)',
            background: '#F1F2F5',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            padding: '0',
            marginTop: '25px',
            justifyContent: 'space-between',
            [theme.breakpoints.down(421)]: {
                width: '225px',
                height: '28px',
            },
        },
        headerTitle: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '296px',
            fontWeight: 500,
            fontSize: '13.3px',
            lineHeight: '26px',
            letterSpacing: '0.4px',
            color: 'rgba(61, 66, 116, 0.3)',
            background: '#F1F2F5',
            paddingLeft: '9px',
            boxSizing: 'initial',
            [theme.breakpoints.down(421)]: {
                fontSize: '10px',
            },
        },
        headerTitleFilled: {
            color: 'rgba(61, 66, 116, 1) !important',
        },
        arrow: {
            marginBottom: '14px',
            marginTop: '16px',
            marginRight: '10px',
        },
        itemContainer: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '296px',
            background: '#FFFFFF',
            [theme.breakpoints.down(421)]: {
                width: '225px',
            },
        },
        dropdownList: {
            fontSize: '13.3px',
            lineHeight: '26px',
            letterSpacing: '0.4px',
            color: 'rgba(61, 66, 116, 0.3)',
            background: '#F1F2F5',
            border: 'none',
            marginTop: '2px',
            textAlign: 'left',
            '&:hover': {
                color: '#3D4274',
                backgroundColor: '#E4E6EC',
            },
            [theme.breakpoints.down(421)]: {
                fontSize: '10px',
            },
        },
    });
