import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '0px',
            marginTop: '250px',
            marginLeft: '30%',
            marginRight: '5%',
            [theme.breakpoints.down(1000)]: {
                marginTop: '150px',
                marginLeft: '37.5%',
            },
            [theme.breakpoints.down(769)]: {
                marginTop: '130px',
                marginLeft: '37.5%',
            },
            [theme.breakpoints.down(651)]: {
                marginTop: '110px',
                marginLeft: '32%',
            },
            [theme.breakpoints.down(551)]: {
                marginTop: '100px',
                marginLeft: '27%',
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '0px',
            },
            [theme.breakpoints.down(351)]: {
                marginLeft: '22%',
            },
        },
        topContainer: {
            marginBottom: '112.84px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down(769)]: {
                marginBottom: '65px',
                flexDirection: 'column',
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '60px',
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '55px',
            },
            [theme.breakpoints.down(551)]: {
                marginBottom: '50px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '45px',
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '40px',
            },
        },
        titleContainer: {
            fontSize: '32px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '2.86px',
            lineHeight: '48px',
            '& #subtitle': { marginBottom: '0px' },
            '& #subtitle1': {
                marginBottom: '0px',
                fontWeight: 900,
                fontSize: '36px',
                letterSpacing: '3.21px',
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '110px',
                '& #subtitle1': {
                    lineHeight: '42px',
                },
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '90px',
                fontSize: '29px',
                '& #subtitle1': {
                    fontSize: '32px',
                    lineHeight: '40px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '80px',
                fontSize: '26px',
                '& #subtitle1': {
                    fontSize: '29px',
                    lineHeight: '37px',
                },
            },
            [theme.breakpoints.down(601)]: {
                '& #subtitle1': {
                    lineHeight: '34px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& #subtitle1': {
                    lineHeight: '31px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '70px',
                fontSize: '22px',
                '& #subtitle1': {
                    fontSize: '25px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(451)]: {
                marginBottom: '60px',
                fontSize: '18px',
                '& #subtitle1': {
                    fontSize: '21px',
                    lineHeight: '25px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '50px',
                fontSize: '16px',
                '& #subtitle1': {
                    fontSize: '19.43px',
                    lineHeight: '22.8px',
                },
            },
        },
        topText: {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            maxWidth: '40%',
            [theme.breakpoints.down(769)]: {
                maxWidth: '68%',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
                maxWidth: '72%',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
                maxWidth: '75%',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
                maxWidth: '80%',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
                maxWidth: '73%',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
                lineHeight: '16px',
                maxWidth: '80%',
            },
            [theme.breakpoints.down(351)]: {
                maxWidth: '95%',
            },
        },
        withMargin: {
            marginLeft: '50px',
        },
    });
