// React imports
import React, { useState, useEffect } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

import savingsWalking from 'images/gifs/savings_walking.gif';
import { styles } from './SavingsBenefits.style';

function SavingsBenefits(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showSafariMargin, setMargin] = useState(false);

    return (
        <Box className={clsx(classes.root, showSafariMargin && 'safari-margin')}>
            <Box className={classes.gif}>
                <img src={savingsWalking} alt="Futuros beneficios de Apartados" />
            </Box>
            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="topTitle">Futuros</p>
                    <div id="title">
                        <p id="top">BENEFICIOS</p>
                    </div>
                </Box>
                <p id="text">
                    Siempre trabajamos para darte el mejor producto y ayudarte a cumplir tus metas. Para mantenerte
                    motivado, pronto podrás configurar notificaciones que te recordarán realizar el apartado de tu
                    dinero, tú definirás cada cuando quieres recibirlas, activarlas o desactivarlas.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(SavingsBenefits);
