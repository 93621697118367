// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Control.style';

function Control(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <p id="subtitle">Tú</p>
                    <p id="subtitle1">TIENES EL</p>
                    <p id="subtitle1" className={classes.withMargin}>
                        CONTROL
                    </p>
                </Box>
                <p className={classes.topText}>
                    Al ingresar a tu app encuentra la sección de "Apartados", en ella define cómo quieres administrar
                    tus fondos, ordenar tus gastos y el tiempo en que deseas hacerlo.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Control);
